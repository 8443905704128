import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '@store/user/actions';
import { navigate } from 'gatsby';
import { useSnackbar } from '@components/common/Snackbar';
import { Box, CircularProgress } from '@mui/material';
import Layout from '@components/common/Layouts/Basic';

const Logout = () => {
	const [openSnackbar] = useSnackbar();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(logoutUser());
		navigate('/login');
		openSnackbar(`You have been successfully logged out.`);
	}, []);

	return (
		<Layout>
			<Box display="flex" justifyContent="center" alignItems="center">
				<CircularProgress />
			</Box>
		</Layout>
	);
};

export default Logout;
